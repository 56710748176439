<template>
  <div>
    <el-form
      :label-position="'left'"
      label-width="80px"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="termId">
        <el-input v-model="termCourse.termId"></el-input>
      </el-form-item>
      <el-form-item label="courseId">
        <el-input v-model="termCourse.courseId"></el-input>
      </el-form-item>
      <el-form-item label="weight">
        <el-input v-model="termCourse.weight"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      :label-position="'left'"
      label-width="80px"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="onSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { alertMessage } from "@/util/alertMessageUtil";
import { termApi } from "@/api/termApi";
export default {
  data() {
    return {
      termCourse: {
        termId: 0,
        courseId: 0,
        weight: 0
      }
    };
  },
  methods: {
    onSave() {
      if (this.termCourse.termId <= 0 || this.termCourse.courseId <= 0) {
        alertMessage("有ID为0", "warning");
        return;
      }
      termApi
        .addTermCourse(this.termCourse)
        .then(ret => {
          console.log(ret);
          if (ret.code == 0) {
            alertMessage("保存成功", "success");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    const termId = this.$route.query.termId;
    const courseId = this.$route.query.courseId;
    if (termId && courseId) {
      termApi.getTermCourse(termId, courseId).then(ret => {
        console.log("---ret.data", ret.data);
        if (ret.code === 0) {
          this.termCourse = ret.data;
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  }
};
</script>

<style></style>
